import React, {useEffect, useState} from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import sampleText from 'asset/json/sample_text.json';
import {useNavigate, useParams} from 'react-router-dom';
import {
  TitleComponent,
  BookComponent,
  LayerBoxComponent,
  CenteredButton,
  SingleLineComponent,
  CustomTextField1,
  CustomTextField2,
  CustomTextField3,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import FooterView from 'view/common/Footer';
import CryptoJS from 'crypto-js';
import APIUtils from 'common/utils/APIUtils';

import emojiRegex from 'emoji-regex';
import {AnalysisText} from 'view/result/AnalysisText';
import {StringUtils} from 'common/utils/StringUtils';
import NoticketPopup from 'view/popup/NoTicket3';

const WritingPage = () => {
  const parameter = useParams();
  const navigate = useNavigate();
  const [sample, setSample] = useState({});
  const [selectValue, setSelectValue] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [bodyText, setBodyText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [nameText, setNameText] = useState('');
  const ageSelectOption = [
    '고3',
    '고2',
    '고1',
    '중3',
    '중2',
    '중1',
    '초6',
    '초5',
    '초4',
    '초3',
    '초2',
    '초1',
    '미취학',
    '성인',
    '대학생',
    '미정',
  ];
  const [ageIndex, setAgeIndex] = useState(0);

  const handleAgeInput = event => {
    setAgeIndex(event.target.value);
  };

  const [exportUserID, setExportUserID] = useState(0);
  const [exportEmail, setExportEmail] = useState('');
  const [exportName, setExportName] = useState('');
  const [loading, setLoading] = useState(false);
  const writingSelectOption = [
    '주장하는 글',
    '설득하는 글',
    '독서 감상문',
    '설명하는 글',
    '소개하는 글',
    '연설을 위한 글',
    '일기',
    '편지',
    '보고서',
    '광고문',
    '기사문',
    '기타 감상문',
    '기타 글짓기',
    // '기행문',
    // '자기소개서',
  ];
  const [writingSelect, setWritingSelect] = useState(0);
  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };

  const [classText, setClassText] = useState('');

  const handleClassInput = event => {
    setClassText(event.target.value);
  };
  const [noticketVisibility, setNoticketVisibility] = useState(false);
  function noticketCancel() {
    setNoticketVisibility(false);
  }
  function noticketComplete() {
    setNoticketVisibility(false);
    navigate('/purchase');
  }
  useEffect(() => {
    const inputVal = parameter.key.toString().replaceAll('$keewi$', '/');
    const decryptedID =
      CryptoJS.AES.decrypt(inputVal, 'T2B0A1I9T2B0A1I9').toString(
        CryptoJS.enc.Utf8,
      ) * 1;
    setExportUserID(decryptedID);
    let dict = {};
    let selectList = [];
    sampleText.forEach((item, index) => {
      dict[item.subject] = item.text;
      selectList.push({value: index, text: item.subject});
    });
    setSample(dict);
    setSelectOption(selectList);
    getPassInfo(decryptedID);
    const getExportInfo = async result => {
      try {
        const response = await APIUtils.AccountInfoExport(decryptedID);
        if (response.data.ret_code == 1000) {
          setExportEmail(response.data.export_email);
          setExportName(response.data.export_name);
          let ageIndex = ageSelectOption.indexOf(response.data.grade_in_charge);
          if (ageIndex == -1) {
            ageIndex = 0;
          }
          setAgeIndex(ageIndex);
        } else {
          navigate('/404');
        }
      } catch (err) {
        console.log(err);
      }
    };
    getExportInfo();
  }, []);
  const handleSelect = event => {
    const dictKey = selectOption[event.target.value].text;

    setSelectValue(event.target.value);
    setBodyText(sample[dictKey]);
    setTitleText(dictKey);
  };
  const handleBody = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 5000) {
        inputVal = inputVal.substring(0, 5000);
      }
      setBodyText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleName = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setNameText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  function submitResult(disabled) {
    if (disabled) {
      alert('학생 이름, 제목, 본문을 모두 입력하셔야 제출하기가 가능합니다.');
      return;
    }
    setLoading(true);
    const analyzeResult = async () => {
      try {
        const response = await APIUtils.EvalAnalyze(
          exportUserID,
          titleText,
          writingSelectOption[writingSelect],
          StringUtils.getTrimmedBody(bodyText),
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const saveResult = async result => {
      try {
        const response = await APIUtils.EvalSave(exportUserID, result);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    analyzeResult()
      .then(res => {
        if (res.data.ret_code == 1000) {
          const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
            res.data.result,
            titleText,
            StringUtils.getTrimmedBody(bodyText),
            ageSelectOption[ageIndex],
            nameText,
          );
          return saveResult(jsonResult);
        }
      })
      .then(r => {
        setLoading(false);
        navigate('/writing/done');
      });
  }
  function getPassInfo(userID) {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(userID);
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.status == 'none') {
            setNoticketVisibility(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function createSampleSelector() {
    if (process.env.REACT_APP_ENV == 'live') {
      return <></>;
    } else {
      return (
        <FormControl
          size="small"
          sx={{
            m: 1,
            minWidth: 200,
            color: Palette.white,
          }}
        >
          <InputLabel
            id="sample-select-label"
            style={{
              color: 'blue',
            }}
          >
            예시 글 가져오기
          </InputLabel>
          <Select
            labelId="sample-select-label"
            id="sample-select"
            onChange={handleSelect}
            value={selectValue}
            label="예시 글 가져오기"
            style={{
              color: Palette.white,
              backgroundColor: Palette.grey1,
            }}
          >
            {selectOption.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  }

  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box style={{width: '100%'}}>
      <NoticketPopup
        visibility={noticketVisibility}
        cancelEvent={noticketCancel}
        completeEvent={noticketComplete}
      />
      <TitleComponent text1={exportName + ' 선생님에게 제출하세요.'} text2="" />
      <BookComponent>
        <>
          {createSampleSelector()}
          <SingleLineComponent
            stepNumber={'01'}
            text="본인의 정보를 입력해 주세요."
          />
          <Grid container style={{marginTop: '0rem'}} spacing={'1.5rem'}>
            <Grid item xs={6}>
              <CustomTextField1
                title="이름"
                emptyText={'이름을 입력해 주세요.'}
                inputText={nameText}
                handleInput={handleName}
                isRequired={true}
              />
            </Grid>
            <Grid item xs={3}>
              <LayerBoxComponent title="학년">
                <Select
                  disableUnderline
                  fullWidth
                  labelId="select-emoji"
                  id="emoji"
                  variant="filled"
                  hiddenLabel
                  onChange={handleAgeInput}
                  value={ageIndex}
                  style={{
                    backgroundColor: '#F7F8FA',
                    border: '1px solid var(--01-gray-07, #E9EBED)',
                    boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                    height: '2.6rem',
                    textAlign: 'center',
                    fontSize: '1rem',
                  }}
                >
                  {ageSelectOption.map((value, index) => {
                    return (
                      <MenuItem
                        style={{
                          justifyContent: 'center',
                          fontSize: '1rem',
                        }}
                        key={'key' + index}
                        value={index}
                      >
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </LayerBoxComponent>
            </Grid>
            <Grid item xs={3}>
              <CustomTextField1
                title="반/번호"
                emptyText={'반/번호'}
                inputText={classText}
                handleInput={handleClassInput}
                isRequired={false}
              />
            </Grid>
          </Grid>
          <Box
            style={{
              height: '1px',
              backgroundColor: '#e9ebed',
              margin: '3rem 0rem',
            }}
          ></Box>
          <SingleLineComponent
            stepNumber={'02'}
            text="제출할 글을 입력해 주세요."
          />
          <Box style={{marginTop: '2.5rem'}} />
          <Box>
            <Box
              style={{
                fontWeight: 'bold',
                color: 'black',
                marginLeft: '0.5rem',
                marginBottom: '1rem',
              }}
            >
              글의 종류<span style={{color: '#35D2BB'}}>*</span>
            </Box>
            <Select
              disableUnderline
              fullWidth
              labelId="select-writing"
              id="wrintingType"
              variant="filled"
              hiddenLabel
              onChange={handleWritingSelectInput}
              value={writingSelect}
              style={{
                backgroundColor: '#F7F8FA',
                border: '0px solid black',
                borderRadius: '0.625rem',
                padding: '0rem 2rem',
                fontSize: '1rem',
              }}
            >
              {writingSelectOption.map((value, index) => {
                return (
                  <MenuItem key={'key' + index} value={index}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField2
            title="제목"
            emptyText={'글의 제목을 50자 내로 입력해 주세요.'}
            inputText={titleText}
            handleInput={handleTitle}
            maxCount="50"
          />
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField3
            title="본문"
            emptyText={`평가할 글을 입력해주세요. 엔터 키[↲] 입력 시 문단이 나뉘었다고 인식합니다.\n문장 부호 외 특수문자/이모지가 포함된 경우, 평가 결과가 정상적으로 출력되지 않을 수 있습니다.`}
            inputText={bodyText}
            handleInput={handleBody}
            maxCount="5000"
          />
          <CenteredButton
            title="제출하기"
            disabled={
              titleText == '' || bodyText.trim() == '' || nameText == ''
            }
            clickEvent={submitResult}
          ></CenteredButton>
        </>
      </BookComponent>
      <FooterView />
    </Box>
  );
};
export default WritingPage;
