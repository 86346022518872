import React from 'react';
import {Box, Button} from '@mui/material';
import logoTBAI from 'asset/image/logo_tbai.png';
import {useNavigate} from 'react-router-dom';

const FooterView = () => {
  const navigate = useNavigate();

  return (
    <Box style={{borderTop: '0.05px solid #000000', width: '100%'}}>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'start',
          color: 'black',
          justifyContent: 'space-between',
          // paddingTop: '1rem',
          paddingBottom: '2rem',
          paddingLeft: '3%',
          paddingRight: '3%',
        }}
      >
        <Box style={{width: '100%'}}>
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <img src={logoTBAI} alt={'logoT'} style={{height: '50px'}} />
          </Box>
          <Box
            ml={2}
            style={{
              fontSize: '0.5rem',
              color: '#717171',
            }}
          >
            <Box>TwoBlockAi | 대표자 : 조영환</Box>
            <Box style={{marginTop: '0.2rem'}}>
              서울시 서초구 남부순환로 350길 54 V-TOWER 1층 | 대표번호 :
              02-6677-0300
            </Box>
            <Box style={{marginTop: '0.2rem'}}>
              사업자등록번호 : 316-88-01580 | 통신판매번호 : 2020-서울서초-4283
            </Box>
            <Box style={{marginTop: '0.2rem'}}>
              문의 : support@twoblockai.com
            </Box>
            <Box style={{display: 'flex', gap: '0.3rem'}}>
              <Box
                style={{
                  color: '#717171',
                  fontSize: '0.5rem',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/term');
                }}
              >
                이용 약관
              </Box>{' '}
              |{' '}
              <Box
                style={{
                  color: '#717171',
                  fontSize: '0.5rem',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/personal');
                }}
              >
                개인정보 처리방침
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              marginTop: '2rem',
              width: '100%',
              textAlign: 'center',
              color: '#717171',
              fontSize: '0.625rem',
              fontWeight: 'bold',
            }}
          >
            © 2023{' '}
            <span
              style={{textDecoration: 'underline', cursor: 'pointer'}}
              onClick={() => {
                window.open('https://twoblockai.com/');
              }}
            >
              TWOBLOCKAi.
            </span>{' '}
            All rights reserved.
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default FooterView;
