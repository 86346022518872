import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import TaskView1 from './TaskView1';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import ApiLoading from 'view/common/ApiLoading2';

export const FragmentView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [loading, setLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  function clickTaskDetail(newTask) {
    props.runningTaskClickEvent(newTask.task_id);
  }
  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function getTaskList() {
    const getTask = async () => {
      try {
        const response = await APIUtils.TaskView(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTask().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        const taskInProgress = res.data.task_list.filter(
          value => value.status == '진행중',
        );
        setTaskList(taskInProgress);
        setTotalPage(
          Math.floor((taskInProgress.length - 1) / countPerPage + 1),
        );
      }
      setLoading(false);
    });
  }
  useEffect(() => {
    getTaskList();
  }, []);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>
        마이페이지 홈
      </Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '2.75rem',
        }}
      />
      <Box
        style={{marginBottom: '1rem', fontSize: '1.125rem', fontWeight: 'bold'}}
      >
        진행 중 과제
      </Box>
      <Box style={{display: taskList.length == 0 ? '' : 'none'}}>
        진행 중 과제가 없습니다.
      </Box>
      <Box>
        {taskList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <TaskView1
                key={'task' + index}
                detailEvent={clickTaskDetail}
                task={value}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.propTypes = {
  runningTaskClickEvent: PropTypes.func,
};
export default FragmentView;
