import React, {useState, useRef, useEffect} from 'react';
import {Box, Button, Select, MenuItem} from '@mui/material';
import {useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import LinearLoading from 'view/common/LinearLoading';
import {AnalysisText} from 'view/result/AnalysisText';
import PropTypes from 'prop-types';
import {StringUtils} from 'common/utils/StringUtils';

const ItemView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const fileInput = useRef();
  const [showFiles, setShowFiles] = useState([]);
  const [confirmFiles, setConfirmFiles] = useState([]);
  const [sendingFileCount, setSendingFileCount] = useState(0);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const ageSelectOption = [
    '고3',
    '고2',
    '고1',
    '중3',
    '중2',
    '중1',
    '초6',
    '초5',
    '초4',
    '초3',
    '초2',
    '초1',
    '미취학',
    '성인',
    '대학생',
    '미정',
  ];
  const writingSelectOption = [
    '주장하는 글',
    '설득하는 글',
    '독서 감상문',
    '설명하는 글',
    '소개하는 글',
    '연설을 위한 글',
    '일기',
    '편지',
    '보고서',
    '광고문',
    '기사문',
    '기타 감상문',
    '기타 글짓기',
    // '기행문',
    // '자기소개서',
  ];
  const [writingSelect, setWritingSelect] = useState(0);
  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };
  const handleAddFiles = event => {
    const currentFiles = fileInput.current.files;
    const dataTranster = new DataTransfer();

    Array.from(currentFiles)
      .concat(
        showFiles.filter(
          item =>
            Array.from(currentFiles)
              .map(f => f.name)
              .indexOf(item.name) == -1,
        ),
      )
      .filter(
        (file, index) =>
          file.name.endsWith('.txt') ||
          file.name.endsWith('.docx') ||
          file.name.endsWith('.doc') ||
          file.name.endsWith('.hwp'),
      )
      .forEach(file => {
        dataTranster.items.add(file);
      });
    fileInput.current.files = dataTranster.files;
    let wholeFileLists = Array.from(dataTranster.files);

    if (wholeFileLists.length > 50) {
      wholeFileLists = wholeFileLists.slice(0, 50);
    }
    setShowFiles(wholeFileLists);
  };
  const handleDeleteFile = id => {
    const currentFiles = fileInput.current.files;
    const dataTranster = new DataTransfer();

    Array.from(currentFiles)
      .filter((file, index) => index != id)
      .forEach(file => {
        dataTranster.items.add(file);
      });

    fileInput.current.files = dataTranster.files;
    setShowFiles(showFiles.filter((_, index) => index !== id));
  };
  function checkFiles() {
    const checkResult = [];
    for (let i = 0; i < showFiles.length; i++) {
      const fileInfo = showFiles[i].name.replace('.txt', '').split('_');
      const title = fileInfo[0];
      const name = fileInfo[1];

      if (1 >= fileInfo.length || 3 < fileInfo.length) {
        checkResult.push(false);
      } else if (title == '' || name == '' || title.length > 50) {
        checkResult.push(false);
      } else {
        checkResult.push(true);
      }
      if (showFiles.length == checkResult.length) {
        setConfirmFiles(checkResult);
      }
    }
  }

  function tryUpload() {
    setLoading(true);
    setSendingFileCount(sendingFileCount => {
      return sendingFileCount + showFiles.length;
    });

    const syncedFileReaderTXT = file =>
      new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.fileName = file.name.replace('.txt', '');
        fileReader.onload = async () => {
          const fileInfo = fileReader.fileName.split('_');
          const title = fileInfo[0];
          const name = fileInfo[1];
          let age = fileInfo[2];
          if (!ageSelectOption.includes(age)) {
            age = '미정';
          }
          resolve(await submitTXTPromise(title, fileReader.result, age, name));
        };
        fileReader.readAsText(file);
      });
    const syncedFileReaderDOC = async file => {
      const formData = new FormData();
      formData.append('file', file);
      const fileName = file.name
        .replace('.docx', '')
        .replace('.doc', '')
        .replace('.hwp', '');
      const fileInfo = fileName.split('_');
      const title = fileInfo[0];
      const name = fileInfo[1];
      let age = fileInfo[2];
      if (!ageSelectOption.includes(age)) {
        age = '미정';
      }
      await submitDOCPromise(title, formData, age, name);
    };
    const uploadFiles = async fileList => {
      for (const file of fileList) {
        if (file.name.endsWith('.txt')) {
          await syncedFileReaderTXT(file);
        } else if (
          file.name.endsWith('.docx') ||
          file.name.endsWith('.doc') ||
          file.name.endsWith('.hwp')
        ) {
          await syncedFileReaderDOC(file);
        } else {
          setSendingFileCount(sendingFileCount => {
            return sendingFileCount - 1;
          });
        }
      }
    };
    uploadFiles(showFiles);
  }

  const submitTXTPromise = async (title, body, age, name) => {
    const newBody = StringUtils.getTrimmedBody(body);
    return new Promise(resolve => {
      const analyzeResult = async () => {
        try {
          const response = await APIUtils.EvalAnalyze(
            authReducer.user_id,
            title,
            writingSelectOption[writingSelect],
            newBody,
          );
          return response;
        } catch (err) {
          console.log(err);
        }
      };
      const saveResult = async result => {
        try {
          const response = await APIUtils.EvalSave(authReducer.user_id, result);
          return response;
        } catch (err) {
          console.log(err);
        }
      };
      analyzeResult()
        .then(res => {
          if (res.data.ret_code == 1000) {
            const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
              res.data.result,
              title,
              newBody,
              age,
              name,
            );
            return saveResult(jsonResult);
          }
        })
        .then(r => {
          setSendingFileCount(sendingFileCount => {
            return sendingFileCount - 1;
          });
          resolve(r);
        });
    });
  };
  const submitDOCPromise = async (title, formData, age, name) => {
    return new Promise(resolve => {
      let orginalText = '';
      let textLength = 0;
      const extractResult = async () => {
        try {
          const response = await APIUtils.ExtractFileKeewi(formData);
          return response;
        } catch (err) {
          console.log(err);
        }
      };
      const analyzeResult = async body => {
        try {
          const response = await APIUtils.EvalAnalyze(
            authReducer.user_id,
            title,
            writingSelectOption[writingSelect],
            body,
          );
          return response;
        } catch (err) {
          console.log(err);
        }
      };
      const saveResult = async result => {
        try {
          const response = await APIUtils.EvalSave(authReducer.user_id, result);
          return response;
        } catch (err) {
          console.log(err);
        }
      };
      extractResult()
        .then(res1 => {
          orginalText = StringUtils.getTrimmedBody(res1.data.body);
          textLength = res1.data.body.length;
          if (res1.data.ret_code == 1000) {
            return analyzeResult(orginalText);
          }
        })
        .then(res2 => {
          if (res2.data.ret_code == 1000) {
            const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
              res2.data.result,
              title,
              orginalText,
              age,
              name,
            );
            return saveResult(jsonResult);
          }
        })
        .then(r => {
          setSendingFileCount(sendingFileCount => {
            return sendingFileCount - 1;
          });
          resolve(r);
        })
        .catch(e => {
          console.log(e);
          setSendingFileCount(sendingFileCount => {
            return sendingFileCount - 1;
          });
          resolve(e);
        });
    });
  };

  useEffect(() => {
    checkFiles();
  }, [showFiles]);
  useEffect(() => {
    if (confirmFiles.length == 0) {
      setSubmitEnabled(false);
    } else if (confirmFiles.every(value => value == true)) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [confirmFiles]);
  useEffect(() => {
    if (sendingFileCount == 0 && loading) {
      setLoading(false);
      props.cancelEvent();
    }
  }, [sendingFileCount]);
  useEffect(() => {
    setShowFiles([]);
    setConfirmFiles([]);
    setLoading(false);
    setSendingFileCount(0);
  }, [props.visibility]);
  if (!props.visibility) {
    return <></>;
  }
  if (loading)
    return (
      <LinearLoading total={showFiles.length} current={sendingFileCount} />
    );
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '80%',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingBottom: '2rem',
          paddingTop: '0.2rem',
          color: 'black',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <Box style={{textAlign: 'right'}}>
          <Button
            onClick={() => {
              props.cancelEvent();
            }}
            style={{color: '#717171', fontSize: '1rem'}}
          >
            ✖
          </Button>
        </Box>
        <Box
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          업로드할 파일을 선택해주세요.
        </Box>
        <Box style={{marginTop: '2rem'}}>
          <Box
            style={{
              fontWeight: 'bold',
              color: 'black',
              fontSize: '0.8rem',
              marginLeft: '0.5rem',
              marginBottom: '1rem',
            }}
          >
            글의 종류<span style={{color: '#35D2BB'}}>*</span>
          </Box>
          <Select
            disableUnderline
            fullWidth
            labelId="select-writing"
            id="wrintingType"
            variant="filled"
            hiddenLabel
            onChange={handleWritingSelectInput}
            value={writingSelect}
            style={{
              backgroundColor: '#F7F8FA',
              border: '0px solid black',
              borderRadius: '0.625rem',
              padding: '0rem 1rem',
              fontSize: '0.75rem',
            }}
          >
            {writingSelectOption.map((value, index) => {
              return (
                <MenuItem
                  key={'key' + index}
                  value={index}
                  style={{fontSize: '0.75rem'}}
                >
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box style={{fontSize: '0.75rem', whiteSpace: 'pre-wrap'}}>
          {
            "① 파일 명은 '제목_이름_학년'이어야 합니다. (학년 형식 예: 초5)\n② 지원하는 파일 형식은 txt, hwp, docx입니다.\n③ 일괄 업로드는 50개까지 가능합니다."
          }
        </Box>

        <Box style={{marginTop: '1rem', marginBottom: '0.5rem'}}>
          <label
            htmlFor="input-file"
            onChange={handleAddFiles}
            style={{display: 'flex', alignItems: 'center'}}
          >
            <input
              type="file"
              id="input-file"
              multiple
              accept=".txt,.docx,.doc,.hwp"
              style={{display: 'none'}}
              ref={fileInput}
            />
            <Button
              style={{
                color: 'black',
                backgroundColor: 'orange',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
              onClick={() => fileInput.current.click()}
            >
              파일 선택하기
            </Button>
            <Box
              style={{
                fontSize: '0.7rem',
                color: '#FF6928',
                whiteSpace: 'pre-wrap',
                marginLeft: '0.5rem',
              }}
            >
              {
                '(O) 행복의 조건_최서희_중2.hwp\n(X) 행복의 조건, 최서희, 중2.hwp'
              }
            </Box>
          </label>

          <Box
            style={{marginTop: '1rem', overflowY: 'scroll', maxHeight: '30rem'}}
          >
            {showFiles.map((file, id) => (
              <Box key={id} style={{fontSize: '0.85rem'}}>
                {confirmFiles[id] ? '🟢' : '🔴'}
                <span style={{marginLeft: '1rem'}}>{file.name}</span>
                <Button onClick={() => handleDeleteFile(id)}>✖</Button>
              </Box>
            ))}
          </Box>
        </Box>
        <Box style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button
            disabled={!submitEnabled}
            style={{
              backgroundColor: submitEnabled ? '#dcf0c7' : '#D9D9D9',
              width: '80%',
              color: 'black',
              marginTop: '1rem',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              paddingTop: '1rem',
              paddingBottom: '1rem',
              borderRadius: '10px',
            }}
            onClick={() => {
              tryUpload();
            }}
          >
            보내기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  cancelEvent: PropTypes.func,
};
export default ItemView;
