import React, {Fragment, useState} from 'react';
import {
  Box,
  Button,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  PurchaseConstant1,
  PurchaseConstant2,
} from 'common/utils/PurchaseConstants';
import {StringUtils} from 'common/utils/StringUtils';
import {PurchaseSelectButton} from 'view/purchase/Styles';

const ItemView = props => {
  const [selectValue, setSelectValue] = useState('카드');
  function handleSelect(evt) {
    setSelectValue(evt.target.value);
  }
  let selectedConstant = [];
  if (props.clickedCard < 4) {
    selectedConstant = PurchaseConstant1;
  } else {
    selectedConstant = PurchaseConstant2;
  }

  if (props.clickedCard == null || props.clickedCard == -1) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          width: '80%',
          maxWidth: '80rem',
          marginTop: '1.5rem',
          color: '#717171',
          fontSize: '0.95rem',
        }}
      >
        본 이용권을 결제하시면,
        {' 오늘(' + StringUtils.getTodayDate() + ')부터 '}
        <span
          style={{
            color: '#ed8e00',
            fontWeight: 'bold',
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
          }}
        >
          {StringUtils.getMonthPlusDate(
            selectedConstant[props.clickedCard % 4].evalDuration,
          ) + '까지'}
        </span>
        {'키위티를 이용하실 수 있습니다.'}
      </Box>
      <FormControl
        style={{
          marginTop: '2rem',
          width: '100%',
          maxWidth: '80rem',
        }}
      >
        <ToggleButtonGroup
          aria-label="select button"
          exclusive
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          value={selectValue}
          onChange={handleSelect}
        >
          <PurchaseSelectButton value="카드">카드 결제</PurchaseSelectButton>
          <PurchaseSelectButton value="토스페이">토스페이</PurchaseSelectButton>
        </ToggleButtonGroup>
      </FormControl>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        <Button
          disabled={props.purchaseDisabled}
          style={{
            color: 'white',
            backgroundColor: props.purchaseDisabled ? '#49545C' : '#35D2BB',
            paddingLeft: '4rem',
            paddingRight: '4rem',
            borderRadius: '0',
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
          onClick={() => props.clickEvent(selectValue)}
        >
          {props.purchaseDisabled ? '결제 불가' : '결제하기'}
        </Button>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  clickedCard: PropTypes.number,
  clickEvent: PropTypes.func,
  purchaseDisabled: PropTypes.bool,
};
export default ItemView;
