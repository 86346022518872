import {Palette} from 'common/utils/ColorUtils';

export const stContent1 = {
  display: 'inline-block',
  width: '80px',
  textAlign: 'right',
  fontWeight: 'normal',
  fontSize: '20px',
};
export const stContent2 = {
  display: 'inline-block',
  width: '80px',
  textAlign: 'right',
  fontWeight: 'normal',
  fontSize: '1rem',
};
export const stLabel1 = {
  display: 'inline-block',
  width: '150px',
  fontWeight: 'normal',
  fontSize: '20px',
  textAlign: 'left',
};
export const stLabel2 = {
  display: 'inline-block',
  fontWeight: 'normal',
  fontSize: '1.2rem',
  textAlign: 'left',
};
export const stScore1 = {
  display: 'inline-block',
  color: '#f67f10',
  textAlign: 'left',
  fontWeight: '700',
  fontSize: '20px',
};
export const stScore2 = {
  display: 'inline-block',
  color: '#f67f10',
  width: '50px',
  textAlign: 'left',
  fontWeight: '700',
  fontSize: '20px',
};
export const stScore3 = {
  display: 'inline-block',
  color: '#f67f10',
  width: '50px',
  textAlign: 'right',
  fontWeight: '700',
  fontSize: '1.3rem',
};
export const commentLeft = {
  fontSize: '15px',
  fontWeight: '700',
  color: 'rgba(48, 48, 48, 1)',
};
export const commentRight = {
  fontWeight: '500',
  fontSize: '15px',
  color: 'rgba(48, 48, 48, 1)',
};
export const chipHelp = {
  fontSize: '10px',
  height: '15px',
  color: 'rgba(0, 0, 0, 1)',
  backgroundColor: 'white',
  border: '1px solid rgba(0, 0, 0, 1)',
};
export const chipHelp2 = {
  fontSize: '10px',
  height: '15px',
  marginTop: '5px',
  color: 'rgba(255, 255, 255, 1)',
  backgroundColor: 'green',
};
export const coloredStyle = {
  coloredLabel: function (mycolor) {
    return {
      color: mycolor,
      fontSize: 'calc(0.3rem + 0.5vw)',
    };
  },
  coloredText: function (mycolor) {
    return {
      backgroundColor: mycolor,
      fontSize: 'calc(0.85rem + 0.3vw)',
      lineHeight: '2.5rem',
      fontWeight: '500',
    };
  },
};
export const gradeBox = {
  backgroundColor: Palette.orange2,
  border: '3px solid' + Palette.orange1,
  borderRadius: '15px',
  padding: '1rem',
};
export const gradeScoreBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  border: '4px dashed' + Palette.orange1,
  borderRadius: '15px',
  aspectRatio: 1,
  padding: '2rem',
  textAlign: 'right',
  width: '9rem',
};
export const gradeTextBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  fontWeight: 'bold',
  fontSize: '1.2rem',
};
export const categoryTextBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  // marginTop: '1rem',
  color: '#2F3941',
  fontSize: '1.125rem',
};
export const overallBox = {
  marginTop: '1rem',
  backgroundColor: '#F7F8FA',
  border: '1px solid #E9EBED',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  padding: '2rem',
  borderRadius: '1.25rem',
  flex: 1,
};
export const overallLeftBox = {
  color: 'black',
  fontWeight: 'bold',
  fontSize: '1.25rem',
};
export const overallTextBox = {
  flex: 1,
  color: 'black',
  wordBreak: 'break-all',
};
export const overallEnterEmojiBox = {
  color: Palette.orange1,
  fontSize: '5rem',
  paddingRight: '2rem',
};
export const modifyBox = {
  marginTop: '5rem',
  marginBottom: '1rem',
  backgroundColor: Palette.white,
  border: '5px solid' + Palette.orange1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  height: '100%',
};
export const modifyLeftBox = {
  color: Palette.orange1,
  width: '4rem',
  height: '4rem',
  backgroundColor: 'white',
};
export const modifyTextBox = {
  flex: 1,
  color: 'black',
  borderLeft: '2px dashed' + Palette.orange1,
  padding: '1rem',
  alignSelf: 'stretch',
  flexDirection: 'row',
  wordBreak: 'break-all',
};
export const modifyEnterEmojiBox = {
  color: Palette.orange1,
  fontSize: '3rem',
  paddingRight: '1.5rem',
};
export const subtitleBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'wrap',
  marginTop: '3.5rem',
  marginBottom: '2rem',
};
export const subtitleLeftBox = {
  fontSize: '1.375rem',
  fontWeight: 'bold',
};
export const subtitleRightBox = {
  color: '#4dc8b6',
  marginLeft: '1rem',
  backgroundColor: '#f0faf9',
  padding: '0.5rem 1.5rem',
  borderRadius: '6.25rem',
};
