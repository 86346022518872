import React from 'react';
import {Box, Button} from '@mui/material';
import logoKakao from 'asset/imageV2/logo_kakao2.png';
import logoKeewit from 'asset/image/logo_keewit.png';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';

const OverlayView = () => {
  const authReducer = useSelector(state => state.authReducer);
  return (
    <Box
      style={{
        paddingBottom: '2rem',
        position: 'fixed',
        bottom: 0,
        right: '5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'end',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F9E000',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
        }}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewit',
            'info',
            window.location.pathname,
            'click_event_open_kakao_chat',
          );
          window.open('http://pf.kakao.com/_XxbmzG/chat', '_blank');
        }}
      >
        <img src={logoKakao} alt="logoKakao" style={{width: '2rem'}} />
        <Box>{'카톡상담'}</Box>
      </Button>
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          marginTop: '1rem',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
        }}
        onClick={() => {
          APIUtils.LoggerLog(
            authReducer.user_id,
            'keewit',
            'info',
            window.location.pathname,
            'click_event_open_keewi.korean.ai',
          );
          window.open(process.env.REACT_APP_LEARN_BASE_URI, '_blank');
        }}
      >
        <img src={logoKeewit} alt="logoKeewit" style={{width: '2rem'}} />
        <Box>{'키위런'}</Box>
      </Button>
    </Box>
  );
};
export default OverlayView;
