import PropTypes from 'prop-types';
import {Box, Button, Checkbox} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState} from 'react';
import {DownloadPDFFile} from 'common/utils/FileUtils';
import APIUtils from 'common/utils/APIUtils';

const HistoryTableItem = props => {
  const [pdfLoading, setPdfLoading] = useState(false);
  function makePDF(eval_id) {
    setPdfLoading(true);
    const makePDFApi = async () => {
      try {
        const response = await APIUtils.CreateEvalRerport(eval_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    makePDFApi().then(res => {
      setPdfLoading(false);
      DownloadPDFFile(res.data, props.title + '_' + props.student_name);
    });
  }
  function getPDFButton() {
    if (pdfLoading) {
      return (
        <Button
          style={{
            color: 'white',
            fontSize: '0.875rem',
            borderRadius: '1.925rem',
            marginTop: '0.5rem',
            padding: '0.5rem 1rem',
            backgroundColor: '#a8b1b7',
            width: '8rem',
          }}
        >
          PDF 생성 중
        </Button>
      );
    }
    return (
      <Button
        style={{
          color: 'white',
          fontSize: '0.875rem',
          borderRadius: '1.925rem',
          marginTop: '0.5rem',
          padding: '0.5rem 1rem',
          backgroundColor: '#ff9900',
          width: '8rem',
        }}
        onClick={() => makePDF(props.eval_id)}
      >
        PDF 다운
      </Button>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #68737D',
        paddingBottom: '1rem',
        paddingTop: '1rem',
      }}
    >
      <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
        <Box>
          <Checkbox
            checked={props.checked}
            onChange={() => {
              props.handleCheck(props.index);
            }}
            inputProps={{'aria-label': 'controlled'}}
            style={{color: '#FF9900'}}
          />
        </Box>
        <Box style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
          <Box
            style={{
              display: 'flex',
              color: '#636C73',
              fontSize: '0.75rem',
              fontWeight: 'bold',
            }}
          >
            {StringUtils.getDateString(props.eval_time)} {' | '}
            {props.student_age}
            {' | '} {props.student_name} {' | '}
            {props.writing_janre}
          </Box>
          <Box style={{fontWeight: 'bold'}}>
            {props.title} {'('}
            {parseFloat(props.score).toFixed(1)}
            {'점)'}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            justifyContent: 'end',
          }}
        >
          <Box
            style={{
              width: '8rem',
              textAlign: 'center',
              fontSize: '0.875rem',
              fontWeight: 'bold',
            }}
          >
            {StringUtils.getDateString(props.last_modify_time)}
          </Box>
          <Button
            style={{
              color: '#262C31',
              fontSize: '0.875rem',
              border: '1.5px solid #262C31',
              borderRadius: '1.925rem',
              marginTop: '0.5rem',
              padding: '0.5rem 1rem',
              backgroundColor: 'white',
              width: '8rem',
            }}
            onClick={() => {
              if (props.disabled) {
                props.noTicketEvent();
              } else {
                props.modifyEvent(props.eval_id);
              }
            }}
            // disabled={props.disabled}
          >
            수정하기
          </Button>
        </Box>
        {getPDFButton()}
      </Box>
    </Box>
  );
};
export default HistoryTableItem;
HistoryTableItem.propTypes = {
  modifyEvent: PropTypes.func,
  noTicketEvent: PropTypes.func,
  handleCheck: PropTypes.func,
  eval_id: PropTypes.number,
  title: PropTypes.string,
  student_age: PropTypes.string,
  eval_time: PropTypes.string,
  last_modify_time: PropTypes.string,
  student_name: PropTypes.string,
  writing_janre: PropTypes.string,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};
