import PropTypes from 'prop-types';
import {Grid, Box, Button, CircularProgress, IconButton} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import create from 'asset/imageV2/icon_mobile_create.png';
import download from 'asset/imageV2/icon_mobile_download.png';
import waiting from 'asset/imageV2/icon_mobile_waiting.svg';
import {DownloadPDFFile} from 'common/utils/FileUtils';
import APIUtils from 'common/utils/APIUtils';

export const HistoryTable = props => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPage = Math.floor((props.history.length - 1) / 15 + 1);
  function clickEvent(page) {
    setCurrentPage(page);
  }
  const [pdfLoading, setPdfLoading] = useState(false);
  function makePDF(eval_id, fileName) {
    setPdfLoading(true);
    const makePDFApi = async () => {
      try {
        const response = await APIUtils.CreateEvalRerport(eval_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    makePDFApi().then(res => {
      setPdfLoading(false);
      DownloadPDFFile(res.data, fileName);
    });
  }
  function getPDFButton(currItem) {
    if (pdfLoading) {
      return (
        <IconButton>
          <img src={waiting} alt={'waiting'} style={{width: '2rem'}} />
        </IconButton>
      );
    }
    return (
      <IconButton
        onClick={() =>
          makePDF(
            currItem.eval_id,
            currItem.title + '_' + currItem.student_name,
          )
        }
      >
        <img src={download} alt={'download'} style={{width: '2rem'}} />
      </IconButton>
    );
  }
  return (
    <Box style={{width: '100%'}}>
      {props.history
        .filter((value, index) => {
          if (Math.floor(index / 15) + 1 == currentPage) {
            return true;
          }
          return false;
        })
        .map((value, index) => {
          return (
            <Box
              key={'historyList' + index}
              style={{
                width: '100%',
                marginTop: '0.5rem',
                boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.15)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  onClick={() => {
                    props.deleteEvent(value.eval_id);
                  }}
                  style={{
                    color: '#717171',
                    fontSize: '0.9rem',
                    padding: '0.7rem',
                  }}
                >
                  ✖
                </Box>
                <Box>
                  <Box
                    style={{
                      color: '#717171',
                      fontSize: '0.8rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.3rem',
                    }}
                  >
                    {StringUtils.getDateString(value.eval_time)}{' '}
                    <Box
                      style={{
                        display: 'inline-block',
                        maxWidth: '5rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      {value.student_name}
                    </Box>
                    {'(' + value.student_age + ')'}
                  </Box>
                  <Box
                    style={{
                      marginTop: '0.3rem',
                      fontSize: '0.95rem',
                      display: 'flex',
                    }}
                  >
                    {'"'}
                    <Box
                      style={{
                        display: 'inline-block',
                        maxWidth: '10rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {value.title}
                    </Box>
                    {'"'} {'('}
                    {value.score}
                    {'점)'}
                  </Box>
                </Box>
              </Box>
              <Box>{getPDFButton(value)}</Box>
            </Box>
          );
        })}
      <Box
        style={{
          marginTop: '3rem',
          fontSize: 'calc(0.8rem + 0.5vw)',
        }}
      >
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          clickEvent={clickEvent}
        />
      </Box>
    </Box>
  );
};
HistoryTable.propTypes = {
  history: PropTypes.array,
  // makeEvent: PropTypes.func,
  // sendEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
};
