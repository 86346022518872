import React, {useEffect, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {StringUtils} from './StringUtils';
import {saveCloseDate} from 'common/reducer/popup';
import {useDispatch, useSelector} from 'react-redux';
import NoticePopup from 'view/popup/Notice';

export function DownloadExcelFile(blobData, fileName) {
  const blob = new Blob([blobData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = URL.createObjectURL(blob);

  const excelName = document.createElement('a');
  excelName.download = `${fileName}.xlsx`;
  excelName.href = link;

  document.body.appendChild(excelName);

  excelName.click();

  document.body.removeChild(excelName);
}
export function DownloadPDFFile(blobData, fileName) {
  const blob = new Blob([blobData]);
  const link = URL.createObjectURL(blob);

  const excelName = document.createElement('a');
  excelName.download = `${fileName}.pdf`;
  excelName.href = link;

  document.body.appendChild(excelName);

  excelName.click();

  document.body.removeChild(excelName);
}
