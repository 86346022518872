import React from 'react';
import RouteRouter from 'common/router';
import 'asset/css/App.css';
import InitializeGoogleAnalytics from 'common/utils/GoogleAnlytics';
import usePreventZoom from 'common/utils/PreventZoom';
import LoginCookie from 'common/utils/LoginCookie';
import CheckVersion from 'common/utils/CheckVersion';
function App() {
  InitializeGoogleAnalytics();
  usePreventZoom();
  LoginCookie();
  CheckVersion();
  return (
    <div key={'App'} className="app">
      <RouteRouter />
    </div>
  );
}

export default App;
