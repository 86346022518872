import {Box, Button, Select, MenuItem, Tooltip, TextField} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import PropTypes, {func} from 'prop-types';
import {StringUtils} from 'common/utils/StringUtils';
import CustomBar from 'view/common/CustomBar';
import BarGraph from 'view/common/BarGraph';
import BarChart from 'view/common/BarChart2';
import TaskEvalView from 'view/mypage/TaskEvalView1';
import {useNavigate, useLocation} from 'react-router-dom';
import TaskUnsubmitPopup from 'view/popup/TaskUnsubmitPopup';
import TaskSubmitPopup from 'view/popup/TaskSubmitPopup';
import TaskSharePopup from 'view/popup/TaskSharePopup';
import iconShare from 'asset/imageV2/icon_share.svg';
import iconShareWhite from 'asset/imageV2/icon_share_white.svg';
import iconDownload from 'asset/imageV2/icon_download1.svg';
import iconDownloadWhite from 'asset/imageV2/icon_download_white.svg';
import iconData from 'asset/imageV2/icon_data.svg';
import iconDataWhite from 'asset/imageV2/icon_data_white.svg';
import iconUnsubmit from 'asset/imageV2/icon_unsubmit.svg';
import iconTooltip from 'asset/imageV2/icon_tooltip.svg';
import Linkify from 'linkify-react';
import ApiLoading from 'view/common/ApiLoading2';
import {DownloadExcelFile} from 'common/utils/FileUtils';
import {GetZipStatus} from 'common/utils/GraphQLUtils';

export const FragmentView = props => {
  const zipStatusData = GetZipStatus(props.taskID);

  useEffect(() => {
    if (zipStatusData == undefined) return;
    setZipStatus(zipStatusData.task.zipStatus);
    setZipURL(zipStatusData.task.zipUrl);
  }, [zipStatusData]);
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [screanLoading, setScreanLoading] = useState(true);
  const [memoText, setMemoText] = useState('');
  const [taskShare, setTaskShare] = useState(0);
  const [zipStatus, setZipStatus] = useState('');
  const [zipURL, setZipURL] = useState('');

  const [taskName, setTaskName] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [taskClass, setTaskClass] = useState([]);
  const [originalSubmitList, setOriginalSubmitList] = useState([]);
  const [submitList, setSubmitList] = useState([]);
  const [submitRate, setSubmitRate] = useState(0);

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');

  const [classList, setClassList] = useState(['전체']);
  const [selectClass, setSelectClass] = useState(0);
  function handleSelectClass(event) {
    setSelectClass(event.target.value);
    setCurrentPage(1);
  }
  const sortingList = ['최신 순', '이름 순', '제목 순'];
  const [sortingDesc, setSortingDesc] = useState(true);
  const [selectSorting, setSelectSorting] = useState(0);
  function handleSelectSorting(event) {
    if (event.target.dataset.value == undefined) {
      return;
    }
    let newValue = Number(event.target.dataset.value);
    if (selectSorting == newValue) {
      setSortingDesc(prev => !prev);
    } else {
      setSortingDesc(true);
    }
    setSelectSorting(newValue);
    setCurrentPage(1);
  }

  const [taskSubmitVisibility, setTaskSubmitVisibility] = useState(false);
  function taskSubmitCancel() {
    setTaskSubmitVisibility(false);
  }

  const [taskUnsubmitVisibility, setTaskUnsubmitVisibility] = useState(false);
  function taskUnsubmitCancel() {
    setTaskUnsubmitVisibility(false);
  }

  const [taskShareVisibility, setTaskShareVisibility] = useState(false);
  function taskShareCancel() {
    setTaskShareVisibility(false);
  }
  function taskShareConfirm(open_score) {
    const taskCompleteReusltApi = async () => {
      try {
        const response = await APIUtils.TaskResultComplete(
          authReducer.user_id,
          props.taskID,
          open_score,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    taskCompleteReusltApi().then(res => {
      setTaskShareVisibility(false);
      getTaskResult();
    });
  }

  const [avgScore, setAvgScore] = useState(0);
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [],
  });
  const [labelData, setLabelData] = useState([]);

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
  };

  function clickEdit(studentID) {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'navigate_modify2',
    );
    window.localStorage.removeItem('text');
    navigate('/modify2', {
      state: {
        task_id: props.taskID,
        student_id: studentID,
      },
    });
  }
  function clickResult(studentID) {
    APIUtils.LoggerLog(
      authReducer.user_id,
      'keewit',
      'info',
      window.location.pathname,
      'navigate_result2',
    );
    navigate('/result2', {
      state: {
        task_id: props.taskID,
        student_id: studentID,
        from: 'finished',
      },
    });
  }

  function reportCreate() {
    setZipStatus('ONGOING');
    const createReportApi = async () => {
      try {
        const response = await APIUtils.CreateTaskReport(props.taskID);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    createReportApi().then(res => {
      // do noting
    });
  }
  function downloadExcel() {
    const excelCreateApi = async () => {
      try {
        const response = await APIUtils.CreateTaskExcel(props.taskID);

        return response;
      } catch (err) {
        console.log(err);
      }
    };
    excelCreateApi().then(res => {
      if (res.status != 200) return;
      DownloadExcelFile(res.data, taskName);
    });
  }
  function getTaskResult() {
    const getTaskReusltApi = async () => {
      try {
        const response = await APIUtils.TaskResult(
          authReducer.user_id,
          props.taskID,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getTaskReusltApi().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setTaskShare(res.data.task_share);
        setTaskName(res.data.task_name);
        setStartTime(new Date(res.data.task_start_time));
        setEndTime(new Date(res.data.task_end_time));
        setTaskClass(res.data.task_class);
        setClassList([
          '전체',
          ...res.data.task_class.map(item => item.class_name),
        ]);
        setMemoText(res.data.task_message);

        setTextCountChecked(res.data.length_check == 1);
        setTextCountMin(res.data.min_length);
        setTextCountMax(res.data.max_length);
        setRequiredWordChecked(res.data.keyword_check == 1);
        setRequiredWord(res.data.keyword);

        const submitedList = res.data.submit_list;
        setOriginalSubmitList(submitedList);
        const totalStudentCount =
          res.data.overall_submission_rate.submit +
          res.data.overall_submission_rate.non_submit;
        setSubmitRate(
          totalStudentCount == 0
            ? 0
            : (
                (res.data.overall_submission_rate.submit / totalStudentCount) *
                100
              ).toFixed(0),
        );
        setAvgScore(
          submitedList.length == 0
            ? 0
            : (
                submitedList.reduce((prev, curr) => prev + curr.score, 0) /
                submitedList.length
              ).toFixed(1),
        );
        const barData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const labelData = [[], [], [], [], [], [], [], [], [], [], []];
        for (let i = 0; i < submitedList.length; i++) {
          const counterIdx = Math.floor(submitedList[i].score / 10);
          barData[counterIdx]++;
          labelData[counterIdx].push(submitedList[i].student_name);
        }
        const data = {
          labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          datasets: [
            {
              label: '',
              data: barData,
              backgroundColor: ({chart: {ctx}}) => {
                const bg = ctx.createLinearGradient(0, 0, 0, 250);
                bg.addColorStop(0, 'rgba(191, 240, 233, 0.7)');
                bg.addColorStop(0.5, 'rgba(48, 211, 187, 1)');
                return bg;
              },
              borderWidth: 0,
              borderRadius: {
                topLeft: 30,
                topRight: 30,
                bottomLeft: 0,
                bottomRight: 0,
              },
              borderSkipped: false,
            },
          ],
        };
        setBarData(data);
        setLabelData(labelData);
      }
      setScreanLoading(false);
    });
  }
  function navigateTaskFragment() {
    props.previousPageEvent();
  }
  function showReportButton() {
    if (taskShare == 0) {
      return (
        <Tooltip
          disabled={submitList.length == 0}
          title="결과 공유를 하셔야 리포트 생성이 가능합니다."
        >
          <span>
            <Button
              style={{
                padding: '0.5rem 1rem',
                whiteSpace: 'nowrap',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: 'white',
                borderRadius: '0.625rem',
                cursor: 'default',
                backgroundColor: '#D9D9D9',
              }}
            >
              <img
                src={iconDownloadWhite}
                alt={'iconDownload'}
                style={{height: '1rem', marginRight: '0.1rem'}}
              />
              리포트 생성
            </Button>
          </span>
        </Tooltip>
      );
    }
    if (zipStatus == 'NO_REQUEST') {
      return (
        <Button
          disabled={submitList.length == 0 || avgScore == 0}
          style={{
            padding: '0.5rem 1rem',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#49545C',
            border: '1px solid #49545C',
            borderRadius: '0.625rem',
          }}
          onClick={() => reportCreate()}
        >
          <img
            src={iconDownload}
            alt={'iconDownload'}
            style={{height: '1rem', marginRight: '0.1rem'}}
          />
          리포트 생성
        </Button>
      );
    } else if (zipStatus == 'ONGOING') {
      return (
        <Tooltip
          disabled={submitList.length == 0}
          title="리포트 생성이 완료되면 다운로드 버튼으로 바뀝니다."
        >
          <span>
            <Button
              disabled={submitList.length == 0 || avgScore == 0}
              style={{
                padding: '0.5rem 1rem',
                whiteSpace: 'nowrap',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: '#49545C',
                border: '1px solid #49545C',
                borderRadius: '0.625rem',
              }}
            >
              생성 중
              <img
                src={iconTooltip}
                alt={'iconTooltip'}
                style={{height: '1rem', marginLeft: '0.2rem'}}
              />
            </Button>
          </span>
        </Tooltip>
      );
    }
    return (
      <Button
        style={{
          padding: '0.5rem 1rem',
          whiteSpace: 'nowrap',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          color: 'white',
          borderRadius: '0.625rem',
          backgroundColor: '#FF9900',
        }}
        onClick={() => {
          window.open(zipURL);
        }}
      >
        <img
          src={iconDownloadWhite}
          alt={'iconDownload'}
          style={{height: '1rem', marginRight: '0.1rem'}}
        />
        리포트 다운
      </Button>
    );
  }
  function showExcelButton() {
    if (taskShare == 0) {
      return (
        <Tooltip
          disabled={submitList.length == 0}
          title="결과 공유를 하셔야 DATA 생성이 가능합니다."
        >
          <span>
            <Button
              disabled={submitList.length == 0}
              style={{
                whiteSpace: 'nowrap',
                color: 'white',
                fontSize: '0.875rem',
                borderRadius: '0.625rem',
                fontWeight: 'bold',
                cursor: 'default',
                backgroundColor: '#D9D9D9',
                padding: '0.5rem 2rem',
              }}
            >
              <img
                src={iconDataWhite}
                alt={'iconData'}
                style={{height: '1rem', marginRight: '0.1rem'}}
              />
              엑셀 생성
            </Button>
          </span>
        </Tooltip>
      );
    }
    return (
      <Button
        style={{
          whiteSpace: 'nowrap',
          color: 'white',
          fontSize: '0.875rem',
          border: '1px solid #4DC8B6',
          borderRadius: '0.625rem',
          fontWeight: 'bold',
          backgroundColor: '#4DC8B6',
          padding: '0.5rem 2rem',
        }}
        onClick={() => {
          // window.open(excelURL);
          downloadExcel();
        }}
      >
        <img
          src={iconDataWhite}
          alt={'iconData'}
          style={{height: '1rem', marginRight: '0.1rem'}}
        />
        엑셀 다운
      </Button>
    );
  }
  useEffect(() => {
    if (props.taskID != 0) {
      getTaskResult();
    }
    const backspace = e => {
      if (
        e.keyIdentifier == 'U+0008' ||
        e.keyIdentifier == 'Backspace' ||
        e.keyCode == 8
      ) {
        if (e.target == document.body) {
          e.preventDefault();
          navigateTaskFragment();
          return false;
        }
      }
    };
    window.addEventListener('keydown', backspace, true);
    const preventGoBack = () => {
      navigateTaskFragment();
      history.pushState(null, '', location.href);
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);

    return () => {
      window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, [props.taskID]);
  useEffect(() => {
    let filteredList = [...originalSubmitList];
    if (searchText.trim() != '') {
      filteredList = filteredList.filter(
        item =>
          item.title.includes(searchText) ||
          item.student_name.includes(searchText),
      );
    }
    if (selectClass != 0) {
      filteredList = filteredList.filter(item =>
        item.class_name_list.includes(classList[selectClass]),
      );
    }
    if (selectSorting == 0) {
      filteredList = filteredList.sort((a, b) => {
        return (
          (new Date(a.submit_time) - new Date(b.submit_time)) *
          (sortingDesc ? 1 : -1)
        );
      });
    } else if (selectSorting == 1) {
      filteredList = filteredList.sort((a, b) => {
        if (a.student_name > b.student_name) {
          return 1 * (sortingDesc ? 1 : -1);
        }
        if (a.student_name < b.student_name) {
          return -1 * (sortingDesc ? 1 : -1);
        }
        return 0;
      });
    } else if (selectSorting == 2) {
      filteredList = filteredList.sort((a, b) => {
        if (a.title > b.title) {
          return 1 * (sortingDesc ? 1 : -1);
        }
        if (a.title < b.title) {
          return -1 * (sortingDesc ? 1 : -1);
        }
        return 0;
      });
    }

    setSubmitList(filteredList);
    setTotalPage(Math.floor((filteredList.length - 1) / countPerPage + 1));
  }, [originalSubmitList, selectClass, selectSorting, sortingDesc, searchText]);
  if (screanLoading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <TaskUnsubmitPopup
        visibility={taskUnsubmitVisibility}
        cancelEvent={taskUnsubmitCancel}
        taskID={props.taskID}
        titleText={taskName}
        startTime={startTime}
        endTime={endTime}
      />
      <TaskSubmitPopup
        visibility={taskSubmitVisibility}
        cancelEvent={taskSubmitCancel}
        studentList={submitList
          .map(val => val.student_name)
          .sort((a, b) => {
            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          })}
        titleText={taskName}
        startTime={startTime}
        endTime={endTime}
      />
      <TaskSharePopup
        visibility={taskShareVisibility}
        cancelEvent={taskShareCancel}
        confirmEvent={taskShareConfirm}
      />
      <Box
        style={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <Box
            style={{
              fontWeight: 'bold',
              fontSize: '1.375rem',
              whiteSpace: 'nowrap',
            }}
          >
            {taskName}
          </Box>
          <Box
            style={{
              color: '#68737D',
              fontSize: '0.875rem',
              marginTop: '0.5rem',
            }}
          >
            {StringUtils.getDateTimeKr(startTime) +
              '~' +
              StringUtils.getDateTimeKr(endTime)}
          </Box>
        </Box>
        <Box style={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
          <Button
            disabled={taskShare != 0 || submitList.length == 0}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor:
                taskShare != 0 || submitList.length == 0 ? '#D9D9D9' : 'white',
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              color:
                taskShare != 0 || submitList.length == 0 ? 'white' : '#ff9900',
              border:
                taskShare != 0 || submitList.length == 0
                  ? ''
                  : '1px solid #ff9900',
              borderRadius: '0.625rem',
            }}
            onClick={() => {
              setTaskShareVisibility(true);
            }}
          >
            <img
              src={
                taskShare != 0 || submitList.length == 0
                  ? iconShareWhite
                  : iconShare
              }
              alt={'iconShare'}
              style={{
                height: '1rem',
                marginRight: '0.1rem',
                whiteSpace: 'nowrap',
              }}
            />
            결과 공유
          </Button>
          {showReportButton()}
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.3rem',
        }}
      />
      <Box
        style={{
          border: '2px solid #35d2bb',
          marginBottom: '1rem',
          padding: '1rem',
          borderRadius: '0.625rem',
          fontSize: '0.875rem',
        }}
      >
        <Box style={{fontWeight: 'bold', color: '#4dc8b6', fontSize: '1rem'}}>
          {'[과제 내용]'}
        </Box>
        <Box
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: '1rem',
          }}
        >
          <Linkify
            options={{
              target: '-blank',
            }}
          >
            {memoText}
          </Linkify>
        </Box>
        <Box
          style={{
            display: textCountChecked || requiredWordChecked ? '' : 'none',
            marginLeft: '1rem',
            marginTop: '1rem',
            fontWeight: 'bold',
          }}
        >
          <Box
            style={{
              display: textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 분량 : ' +
              textCountMin +
              ' 자 ~ ' +
              textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + requiredWord}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          padding: '2rem',
          border: '1px solid #D8DCDE',
          borderRadius: '1.25rem',
          maxHeight: '15rem',
          minHeight: '10rem',
          overflowY: 'auto',
          display: 'flex',
          gap: '3rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Box style={{whiteSpace: 'nowrap', fontWeight: '500'}}>
              전체 제출률
            </Box>
            <Box
              style={{
                color: '#5ECECE',
                fontWeight: 'bold',
                fontSize: '1rem',
                border: '1px solid #E6E8EB',
                boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.03)',
                padding: '0.3rem 1rem',
                borderRadius: '0.5rem',
              }}
            >
              {submitRate + ' %'}
            </Box>
          </Box>
          <Button
            style={{
              border: '1px solid #D8DCDE',
              borderRadius: '0.625rem',
              color: '#49545C',
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
            }}
            onClick={() => setTaskUnsubmitVisibility(true)}
          >
            <img
              src={iconUnsubmit}
              alt="iconUnsubmit"
              style={{marginRight: '0.5rem'}}
            />
            미제출자
          </Button>
          <Button
            style={{
              border: '1px solid #D8DCDE',
              borderRadius: '0.625rem',
              color: '#49545C',
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
            }}
            onClick={() => setTaskSubmitVisibility(true)}
          >
            <img
              src={iconUnsubmit}
              alt="iconUnsubmit"
              style={{marginRight: '0.5rem'}}
            />
            제출자
          </Button>
        </Box>
        <Box style={{width: '100%'}}>
          <BarGraph taskClass={taskClass} maxCount={15} />
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '0.5rem',
          padding: '1rem',
          border: '1px solid #D8DCDE',
          borderRadius: '1.25rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Box style={{color: '#49545C', fontWeight: 'bold'}}>
            전체 평균
            <span
              style={{
                color: '#35D2BB',
                marginLeft: '0.5rem',
                fontWeight: 'bold',
              }}
            >
              {avgScore + '점'}
            </span>
          </Box>
          {showExcelButton()}
        </Box>
        <Box
          style={{
            height: '20rem',
            width: '80%',
            marginLeft: '10%',
            marginTop: '2rem',
          }}
        >
          <BarChart barData={barData} labelData={labelData} />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '3rem',
          gap: '1rem',
        }}
      >
        <Select
          disableUnderline
          // onChange={handleSelectSorting}
          onClick={handleSelectSorting}
          value={selectSorting}
          variant="standard"
          style={{
            border: '1px solid #E9EBED',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: 'black',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
        >
          {sortingList.map((value, index) => (
            <MenuItem key={index} value={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <Select
          disableUnderline
          onChange={handleSelectClass}
          value={selectClass}
          variant="standard"
          style={{
            border: '1px solid #E9EBED',
            height: '2.5rem',
            borderRadius: '0.9rem',
            color: 'black',
            fontSize: '0.875rem',
            whiteSpace: 'nowrap',
            padding: '0rem 1rem',
          }}
        >
          {classList.map((value, index) => (
            <MenuItem key={index} value={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <Box>
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid black',
                },
                '&:hover fieldset': {
                  border: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid black',
                },
              },
            }}
            label={searchText == '' ? '🔍 검색(제목/이름)' : ''}
            InputLabelProps={{
              style: {
                color: 'rgba(108, 108, 108, 0.721)',
                fontSize: 'calc(0.3rem + 0.5vw)',
              },
              shrink: false,
            }}
            InputProps={{style: {fontSize: 'calc(0.3rem + 0.5vw)'}}}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
        </Box>
      </Box>
      <Box style={{marginTop: '1rem'}}>
        {submitList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <TaskEvalView
                key={'taskEval' + index}
                submitTask={value}
                endTime={endTime.toString()}
                editEvent={clickEdit}
                resultEvent={clickResult}
                taskShare={taskShare == 1}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.propTypes = {
  taskID: PropTypes.number,
  previousPageEvent: PropTypes.func,
};
export default FragmentView;
