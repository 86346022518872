import React from 'react';
import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {saveLogin} from 'common/reducer/auth';
import GoogleButton from 'view/login/GoogleButton';
import NaverButton from 'view/login/NaverButton';
import KakaoButton from 'view/login/KakaoButton';
import HeaderView from 'view/common/Header';
import {
  wholeLoginPage,
  loginTitle1,
  loginTitle2,
  loginKakaoBox,
  loginNaverBox,
  loginGoogleBox,
} from 'view/login/Styles';
import APIUtils from 'common/utils/APIUtils';
import FooterView from 'view/common/Footer';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);

  function onGoogleSuccess(token) {
    callLoginAPI('google', token);
  }
  function onNaverSuccess(token) {
    callLoginAPI('naver', token);
  }
  function onKakaoSuccess(token) {
    callLoginAPI('kakao', token);
  }
  function callLoginAPI(access_path, access_token) {
    const loginResult = async (path, token) => {
      try {
        const response = await APIUtils.AccountLogin(path, token);
        if (response.data.ret_code == 1000) {
          dispatch(
            saveLogin({
              user_id: response.data.user_id,
              user_name: response.data.user_name,
              user_email: response.data.email,
              export_name: response.data.export_name,
              export_email: response.data.export_email,
              grade_in_charge: response.data.grade_in_charge,
              path: path,
              user_token: token,
              group_name: response.data.group_name,
              is_group_admin: response.data.is_group_admin,
              auth_key: response.data.auth_key,
            }),
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    loginResult(access_path, access_token).then(r => {
      gotoPrevPage();
    });
  }
  function gotoPrevPage() {
    if (authReducer.prevPage == '/result') {
      navigate(authReducer.prevPage, {
        replace: true,
        state: {
          title: authReducer.title,
          text: authReducer.text,
          student_age: authReducer.student_age,
          student_name: authReducer.student_name,
        },
      });
    } else {
      navigate(authReducer.prevPage, {replace: true});
    }
  }
  return (
    <Box style={wholeLoginPage}>
      <HeaderView />
      <Box style={{minHeight: '55vh', marginBottom: '8rem'}}>
        <Box style={loginTitle1}>맞춤법 교정부터 문맥 파악까지</Box>
        <Box style={loginTitle2}>
          인공지능 글쓰기 평가
          <br />
          키위티, 간편하게 시작하세요.
        </Box>
        <Box style={{display: 'flex', justifyContent: 'end'}}>
          <Button
            style={{
              color: 'blue',
              textDecoration: 'underline',
              marginTop: '4rem',
              fontWeight: 'bold',
            }}
            onClick={() => {
              APIUtils.LoggerLog(
                authReducer.user_id,
                'keewit',
                'info',
                window.location.pathname,
                'click_event_open_keewi.korean.ai',
              );
              window.open('https://keewi.korean.ai');
            }}
          >
            학생이신가요?
          </Button>
        </Box>
        <Box style={loginKakaoBox}>
          <KakaoButton onSuccess={onKakaoSuccess} />
        </Box>
        <Box style={loginNaverBox}>
          <NaverButton onSuccess={onNaverSuccess} />
        </Box>
        <Box style={loginGoogleBox}>
          <GoogleButton onSuccess={onGoogleSuccess} />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default LoginPage;
