import PropTypes from 'prop-types';
import {Grid, Box, Button} from '@mui/material';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import HistoryTableItem from 'view/mypage/HistoryTableItem';

const HistoryTable = props => {
  const [currentPage, setCurrentPage] = useState(1);
  const [checkList, setCheckList] = useState([]);
  const numPerPage = 15;
  const totalPage = Math.floor((props.history.length - 1) / numPerPage + 1);
  function clickEvent(page) {
    setCurrentPage(page);
  }
  function handleCheck(idx) {
    const temp = [...checkList];

    const removeIdx = temp.indexOf(idx);
    if (removeIdx == -1) {
      temp.push(idx);
    } else {
      temp.splice(removeIdx, 1);
    }
    setCheckList(temp);
  }
  function handleCheckAll() {
    if (
      props.history.filter((value, index) => {
        if (Math.floor(index / numPerPage) + 1 == currentPage) {
          return true;
        }
        return false;
      }).length == checkList.length
    ) {
      setCheckList([]);
    } else {
      const checkLength = props.history.filter((value, index) => {
        if (Math.floor(index / numPerPage) + 1 == currentPage) {
          return true;
        }
        return false;
      }).length;
      setCheckList([...Array(checkLength).keys()]);
    }
  }
  useEffect(() => {
    setCheckList([]);
  }, [currentPage]);
  return (
    <Box style={{width: '100%'}}>
      <Box>
        {props.history
          .filter((value, index) => {
            if (Math.floor(index / numPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <HistoryTableItem
                key={'historyList' + index}
                modifyEvent={props.modifyEvent}
                noTicketEvent={props.noTicketEvent}
                handleCheck={handleCheck}
                eval_id={value.eval_id}
                title={value.title}
                student_age={value.student_age}
                eval_time={value.eval_time}
                last_modify_time={value.last_modify_time}
                student_name={value.student_name}
                score={value.score}
                writing_janre={value.writing_janre}
                index={index}
                disabled={props.disabled}
                checked={checkList.includes(index)}
              />
            );
          })}
      </Box>

      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'start',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Button
          style={{
            marginLeft: '0.5rem',
            marginRight: '1rem',
            border: '1px solid black',
            color: 'black',
            fontSize: '0.875rem',
          }}
          onClick={() => {
            props.multiDeleteEvent(
              checkList.map(i => i + (currentPage - 1) * numPerPage),
            );
            setCheckList([]);
          }}
        >
          선택 삭제
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingBottom: '2rem',
          fontWeight: 'bold',
          fontSize: 'calc(0.8rem + 0.5vw)',
        }}
      >
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          clickEvent={clickEvent}
        />
      </Grid>
    </Box>
  );
};
HistoryTable.propTypes = {
  history: PropTypes.array,
  modifyEvent: PropTypes.func,
  noTicketEvent: PropTypes.func,
  multiDeleteEvent: PropTypes.func,
  disabled: PropTypes.bool,
};
export default HistoryTable;
