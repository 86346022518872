import React, {useState, useRef} from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/esm/locale';
import {StringUtils} from 'common/utils/StringUtils';
const ExampleCustomInput = React.forwardRef((props, ref) => (
  <button
    style={{
      backgroundColor: '#F7F8FA',
      border: '0px solid black',
      borderRadius: '0.625rem',
      padding: '0.5rem 1rem',
    }}
    className="custom-input"
    onClick={props.onClick}
    ref={ref}
  >
    {props.value}
  </button>
));
ExampleCustomInput.displayName = 'CalandarInput';
ExampleCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};
const ItemView = props => {
  const writingSelectOption = [
    '주장하는 글',
    '설득하는 글',
    '독서 감상문',
    '설명하는 글',
    '소개하는 글',
    '연설을 위한 글',
    '일기',
    '편지',
    '보고서',
    '광고문',
    '기사문',
    '기타 감상문',
    '기타 글짓기',
    // '기행문',
    // '자기소개서',
  ];
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          variant="filled"
          fullWidth
          label={props.titleText == '' ? '과제 이름' : ''}
          sx={{
            width: '23rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderBottom: '1px solid black',
              },
              '&:hover fieldset': {
                borderBottom: '1px solid black',
              },
              '&.Mui-focused fieldset': {
                borderBottom: '1px solid black',
              },
            },
          }}
          InputLabelProps={{
            style: {
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: '#A8B1B7',
            },
            shrink: false,
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              fontWeight: 'bold',
              color: 'black',
              backgroundColor: 'white',
              fontSize: '1.5rem',
              borderBottom: '1px solid black',
            },
          }}
          value={props.titleText}
          onChange={props.handleTitle}
        ></TextField>
        <IconButton onClick={props.cancelEvent}>
          <img src={IconCancel} />
        </IconButton>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box style={{display: props.startEditable ? '' : 'none'}}>
          <DatePicker
            locale={ko}
            dateFormat={'yyyy년 MM월 dd일 HH:mm'}
            shouldCloseOnSelect
            minDate={new Date()}
            maxDate={props.startDateMax}
            selected={props.startDate}
            onChange={props.startDateChange}
            customInput={<ExampleCustomInput />}
            showTimeSelect
            style={{width: '20rem'}}
          />
        </Box>
        <Box style={{display: props.startEditable ? 'none' : ''}}>
          {StringUtils.getDateTimeKr(props.startDate)}
        </Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>~</Box>
        <DatePicker
          locale={ko}
          dateFormat={'yyyy년 MM월 dd일 HH:mm'}
          shouldCloseOnSelect
          minDate={props.startDate}
          maxDate={props.endDateMax}
          selected={props.endDate}
          onChange={props.endDateChange}
          customInput={<ExampleCustomInput />}
          showTimeSelect
          style={{width: '20rem'}}
        />
      </Box>
      <Box style={{fontSize: '0.75rem', color: '#A8B1B7', marginTop: '0.5rem'}}>
        과제 진행 기간은 최대 3개월입니다.
      </Box>
      <Box
        style={{
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box style={{fontWeight: '500'}}>평가 장르 선택</Box>
        <Box style={{width: '60%'}}>
          <Select
            disableUnderline
            fullWidth
            labelId="select-writing"
            id="wrintingType"
            variant="filled"
            hiddenLabel
            onChange={props.handleWritingSelectInput}
            value={props.writingSelect}
            style={{
              backgroundColor: '#F7F8FA',
              border: '0px solid black',
              borderRadius: '0.625rem',
              fontSize: '0.875rem',
            }}
          >
            {writingSelectOption.map((value, index) => {
              return (
                <MenuItem
                  key={'key' + index}
                  value={index}
                  style={{fontSize: '0.875rem'}}
                >
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{fontWeight: '500'}}>주제(메시지, 문항) 입력</Box>
        <Box>
          <span style={{color: '#35D2BB'}}>{props.memoText.length}</span>/500
        </Box>
      </Box>
      <Box style={{display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
        <TextField
          autoComplete="off"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&:hover fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #EBEEF4',
                borderRadius: '0.625rem',
              },
            },
          }}
          variant="outlined"
          fullWidth
          InputProps={{
            style: {
              backgroundColor: '#F7F8FA',
              fontWeight: 500,
            },
          }}
          rows={10}
          multiline
          value={props.memoText}
          onChange={props.handleMemo}
        ></TextField>
      </Box>
      <Box style={{marginTop: '2rem', display: 'flex'}}>
        <Button
          disabled={props.titleText == '' || props.memoText == ''}
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            color: 'white',
            backgroundColor:
              props.titleText == '' || props.memoText == ''
                ? '#A8B1B7'
                : '#35D2BB',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            padding: '0.9rem 0rem',
            marginLeft: '1rem',
            borderRadius: '6.25rem',
          }}
          onClick={() => props.nextStepEvent()}
        >
          {'step 02. 과제 조건 설정하기'}
        </Button>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  titleText: PropTypes.string,
  handleTitle: PropTypes.func,
  memoText: PropTypes.string,
  handleMemo: PropTypes.func,
  startEditable: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  startDateChange: PropTypes.func,
  startDateMax: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  endDateChange: PropTypes.func,
  endDateMax: PropTypes.instanceOf(Date),
  writingSelect: PropTypes.number,
  handleWritingSelectInput: PropTypes.func,
  cancelEvent: PropTypes.func,
  nextStepEvent: PropTypes.func,
};
export default ItemView;
